import { css } from '@emotion/react'
import { Link } from 'gatsby'

const footerCss = {
  self: css({
    borderTop: '2px solid var(--charcoal)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  ul: css({
    display: 'flex',
    listStyle: 'none',
    padding: '8px 16px',
    margin: 0,
    li: {
      padding: '0 8px',
      ':not(:last-child)': {
        borderRight: '2px solid var(--charcoal)',
      },
    },
  }),
  small: css({ padding: 8 }),
}

export default function Footer() {
  return (
    <footer css={footerCss.self}>
      <ul css={footerCss.ul}>
        <li>
          <a href="https://twitter.com/minmaxfm" target="_blank">
            Твіттер
          </a>
        </li>
        <li>
          <a href="mailto:minmax@gmail.com">Пошта</a>
        </li>
      </ul>
      <small css={footerCss.small}>
        &copy;{new Date().getFullYear()}, написано на JavaScript і обмазано{' '}
        <a href="https://www.gatsbyjs.com" target="_blank">
          Gatsby.js
        </a>
      </small>
    </footer>
  )
}
