import * as React from 'react'
import { Global } from '@emotion/react'
import Footer from './footer'
import Header from './header'

const grayScale = {
  '--very-light-gray': '#E6E6E6', // Primary font color
  '--very-light-gray-fade': '#FFFFFF',
  '--lighter-gray': '#CDCDCD',
  '--lighter-gray-fade': '#CDCDCD30',
  '--contrast-gray': '#BBBBBB',
  '--light-gray': '#757575',
  '--light-midnight': '#676B79',
  '--steel-gray': '#3E4250',
  '--gray': '#373B41',
  '--gray-fade': '#373B4199',
  '--seal': '#31353a', // Light background
  '--very-dark-gray': '#2a2c2d', // Dark background
  '--charcoal': '#222223', // Use for borders between gray backgrounds
}

const pinkRedOrange = {
  '--red': '#FF2C6D',
  '--light-red': '#FF4B82',
  '--orange': '#FFB86C',
  '--light-orange': '#FFCC95',
  '--light-orange-fade': '#FFCC9560',
  '--pink': '#FF75B5',
  '--light-pink': '#FF9AC1',
  '--light-pink-fade': '#FF9AC170',
}

const bluePurpleGreen = {
  '--blue': '#45A9F9',
  '--light-blue': '#6FC1FF',
  '--purple': '#B084EB',
  '--purple-fade': '#B084EB60',
  '--light-purple': '#BCAAFE',
  '--green': '#19f9d8',
  '--light-green': '#6FE7D2',
  '--green-fade': '#19f9d899',
}

const elementColors = {
  //  Two backgrounds are used to provide contrast between section in editor.
  //  Foreground color for normal font, use green for contrast fonts
  '--background-dark': '#242526', // very-dark-gray
  '--background-light': '#292A2B', // seal
  '--foreground': '#E6E6E6', // very-light-gray
  '--diff-green': '#19f9d866',
  '--diff-red': '#FF4B8266',
  '--merge-current-header': '#B084EB90', //purple with alpha
  '--merge-current-content': '#B084EB40', // same purple less alpha
  '--merge-incoming-header': '#45A9F990', //orange with alpha
  '--merge-incoming-content': '#FFB86C40', // same orange less alpha
  '--transparent': '#00000000',
  // Git Colors
  '--git-modified': '#FFCC95', // light-orange
  '--git-added': '#19f9d8', // green
  '--git-removed': '#FF4B82', // light-red
  '--git-ignored': '#757575', // light-grey
  // Linter Colors
  '--error': '#FF4B82', // light-red
  '--warning': '#FFCC95', // light-orange
  '--info': '#6FC1FF', // light-blue
}

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Global
        styles={{
          ':root': {
            ...grayScale,
            ...pinkRedOrange,
            ...bluePurpleGreen,
            ...elementColors,
          },
          body: {
            background: 'var(--seal)',
            color: 'var(--very-light-gray)',
            fontFamily: 'Open Sans, sans-serif',
            margin: 'auto',
          },
          '#gatsby-focus-wrapper': {
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            justifyContent: 'stretch',
          },
          h1: {
            color: 'var(--very-light-gray-fade)',
          },
          'h2, h3, h4, h5, h6': {
            color: 'var(--lighter-gray)',
          },
          a: {
            color: 'var(--blue)',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
            '&:active': {
              color: 'var(--light-blue)',
            },
            '&:visited': {
              color: 'var(--purple)',
            },
          },
          main: {
            margin: '0 auto',
            maxWidth: 768,
            padding: '0 16px',
            flex: 1,
          },
          'header, footer': {
            background: 'var(--very-dark-gray)',
          },
        }}
      />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}
